<template>
  <div v-show="store.ready" class="md:w-screen md:max-h-screen bg-neutral-white">
    <ToastController />
    <div
      v-show="!store.mobileMenuOpen"
      class="fixed inset-x-0 top-0 flex z-50 items-center justify-between bg-neutral-white p-4 border-b border-neutral-300 md:hidden"
    >
      <PulseCircleLogo class="h-8 w-8" />
      <button class="text-neutral-500">
        <PulseLineBars class="h-6 w-6 cursor-pointer fill-current" @click="store.toggleMobileMenu" />
      </button>
    </div>
    <div v-show="!loading" class="dashboard-layout-collapsed">
      <div class="hidden absolute left-0 top-0 bottom-0 md:grid grid-cols-2" style="grid-template-columns: auto 1fr">
        <div
          class="border-solid border-r border-neutral-300 group transition-all ease-in-out duration-150 z-50 overflow-auto"
          :class="{
            'w-[250px]': !showCollapsed,
            'w-[63px]': showCollapsed,
          }"
          @mouseover="menuState.collapsed = false"
          @mouseleave="menuState.collapsed = true"
        >
          <SideNav style="flex: none; overflow: auto" @store-select:click="toggleStoreSelect" />
        </div>
        <StoreSelect :open="menuState.storeSelect" @close="closeMenu" />
      </div>
      <div />
      <div class="md:p-6" :class="{ 'overflow-clip': layoutType === 'claim' }">
        <div
          class="border-solid border-neutral-300 rounded-lg md:max-h-full"
          :class="{ 'overflow-clip': layoutType === 'claim' }"
          :style="{
            boxShadow: '0px 8px 32px 0px #00000029',
          }"
        >
          <div class="border-b border-solid border-neutral-300 px-4 md:px-8 py-4 flex items-center justify-between">
            <div v-if="layoutType === 'claim'" class="flex items-center gap-1">
              <PulseIconButton
                icon="LineTimes"
                variant="secondary"
                size="large"
                class="mr-2"
                @click="handleCLaimClose"
              />
              <PulseIconButton
                icon="LineAngleUp"
                variant="secondary"
                size="large"
                :disabled="$attrs.nav?.disableUp"
                @click="handleGoingUp"
              />
              <PulseIconButton
                icon="LineAngleDown"
                variant="secondary"
                size="large"
                :disabled="$attrs.nav?.disableDown"
                @click="handleGoingDown"
              />
            </div>
            <div v-else class="flex items-center gap-1">
              <PulseIconButton
                v-if="(isMobile && showMacroMenu) || !isMobile"
                icon="LineTimes"
                variant="secondary"
                size="large"
                class="mr-2"
                @click="navigateTo('/claims')"
              />
              <PulseIconButton
                v-else-if="isMobile && !showMacroMenu"
                icon="LineArrowLeft"
                variant="secondary"
                size="large"
                class="mr-2"
                @click="$emit('showMacroMenu', true)"
              />
            </div>

            <div class="hidden md:flex gap-4">
              <slot name="actions" />
              <PulseIconButton
                v-if="$slots.aside"
                icon="LineBolt"
                variant="secondary"
                size="large"
                @click="showContext = !showContext"
              />
            </div>
          </div>

          <div
            class="solo-content-container"
            :class="{
              split: $slots.aside && showContext,
            }"
          >
            <div
              ref="content"
              class="transition-all ease-in-out scrollbar-hidden"
              :class="{
                'p-4 md:px-12 md:py-8 overflow-auto': layoutType === 'claim',
                'overflow-hidden': layoutType === 'macro',
              }"
            >
              <slot />
            </div>

            <div v-if="showContext && $slots.aside" class="border-l border-neutral-300 border-solid">
              <slot name="aside" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading" class="w-screen h-screen flex items-center justify-center">
      <PulseCircleLogo class="h-8 w-8" />
    </div>

    <div v-if="store.mobileMenuOpen" class="fixed right-0 top-0 bottom-0 left-0 overflow-auto z-[100]">
      <div class="fixed inset-0 bg-black opacity-70" />
      <SideNav class="z-50 bg-neutral-white fixed w-[276px] right-0" @menu:click="store.closeMenu">
        <template #close>
          <div class="p-5 -mt-8">
            <PulseIconButton icon="IconClose" variant="secondary" size="large" @click="store.toggleMobileMenu" />
          </div>
        </template>
      </SideNav>
    </div>
  </div>

  <SearchModal />
</template>

<script setup lang="ts">
import useWindowSize from '~/composables/useWindowSize';
import { useApplicationStore } from '~/store/application';
import { useUserStore } from '~/store/user';

defineProps({
  layoutType: {
    required: true,
    type: String,
  },
  showMacroMenu: {
    default: false,
    required: false,
    type: Boolean,
  },
});

const router = useRouter();
const { isMobile } = useWindowSize();

const emit = defineEmits(['showMacroMenu', 'nav-up', 'nav-down']);

const loading = computed(() => {
  return useUserStore().loading;
});
const store = useApplicationStore();
const layoutCustomProps = useAttrs();

const content = ref<HTMLElement | null>(null);

const menuState = reactive({
  collapsed: true,
  storeSelect: false,
});

function closeMenu() {
  menuState.storeSelect = false;
}

function toggleStoreSelect() {
  menuState.storeSelect = !menuState.storeSelect;
}

function handleCLaimClose() {
  if (window.history.length <= 2) navigateTo('/claims/todo');
  else router.back();
}

function handleGoingUp() {
  if (layoutCustomProps['is-nav-handled-externally']) emit('nav-up');
  else handleCLaimClose();
}

function handleGoingDown() {
  if (layoutCustomProps['is-nav-handled-externally']) emit('nav-down');
  else handleCLaimClose();
}

const showCollapsed = computed(() => {
  if (menuState.storeSelect) {
    return false;
  }

  if (isMobile.value) {
    return false;
  }

  return menuState.collapsed;
});

const showContext = ref(true);

function handleResize() {
  if (!content.value) return;
  content.value.style.maxHeight = `${window.innerHeight - 100}px`;
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

provide('collapsed', showCollapsed);
provide('context', showContext);

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - OrderProtection` : 'Welcome - OrderProtection';
  },
});
</script>

<style lang="scss">
.dashboard-layout-collapsed {
  @screen md {
    grid-template-columns: 63px 1fr;
    grid-template-rows: 1fr;
    @apply mt-0;
  }

  @screen lg {
    @apply h-screen overflow-hidden;
  }

  @apply mt-20 grid overflow-hidden; // this will need to change
}

.fill-current {
  svg {
    fill: currentColor;
  }
}

.solo-content-container {
  display: grid;
  grid-template-columns: auto;
  height: calc(100vh - 7rem);

  &.split {
    @screen md {
      grid-template-columns: 1fr minmax(0, 300px);
    }

    @screen lg {
      grid-template-columns: 1fr minmax(0, 562px);
    }
  }
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
